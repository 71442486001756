<!--
  Guide to hosting graphiQL:
  https://github.com/graphql/graphiql/blob/master/packages/graphiql-examples/cdn/index.html

-->
<template>
  <v-layout column>
    <v-flex>
      <div ref="playground" class="xs12" style="height: 100%"></div>
    </v-flex>
    <v-flex>
      <v-layout row>
        <v-flex>
          <v-textarea v-model="transformScript"></v-textarea>
        </v-flex>
        <v-flex>
          {{parsing}}
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>
<script>
const acorn = require('acorn')
// const esprima = require('esprima')
const sandboxr = require('sandboxr')
import axios from 'axios'

const defaultScript = `
export default {
map(x) { return x }
}`

export default {
  data: () => ({
    transformScript: defaultScript,
    parsing: null
  }),
  async mounted () {
    this.$nextTick(() => this.initialize())
  },
  methods: {
    async initialize () {
      const scripts = [
        // '//cdn.jsdelivr.net/es6-promise/4.0.5/es6-promise.auto.min.js',
        // '//cdn.jsdelivr.net/fetch/0.9.0/fetch.min.js',
        '//cdn.jsdelivr.net/react/15.4.2/react.min.js',
        '//cdn.jsdelivr.net/react/15.4.2/react-dom.min.js',
        'https://cdnjs.cloudflare.com/ajax/libs/graphiql/0.14.2/graphiql.min.js',
        // 'https://cdnjs.cloudflare.com/ajax/libs/acorn/7.1.0/acorn.min.js'
      ]
      const styles = ['https://cdnjs.cloudflare.com/ajax/libs/graphiql/0.14.2/graphiql.min.css']

      await this.loadStyles(styles)
      await this.loadScripts(scripts)

      let self = this

      async function fetcher (graphQLParams) {
        let {data} = await axios.post('/api/v1/graphql', {
          ...graphQLParams,
          transform: graphQLParams.operationName === 'IntrospectionQuery' ? undefined : self.transformScript
        })
        // self.processData(data)
        return data
      }

      let {ReactDOM, React, GraphiQL} = window
      ReactDOM.render(
        React.createElement(GraphiQL,
          {
            fetcher,
            /*
            async fetcher (graphQLParams) {
              let {data} = await axios.post('/api/v1/graphql', {
                ...graphQLParams,
                transform: self.transformScript
              })
              // self.processData(data)
              return data
            },
            */
            query: `
  {
    articles {
      articleNo,
      title
    }
  }
            `
          },
        ),
        this.$refs.playground)
    },
    processData (d) {
      // this.$refs.sandbox.contentWindow.postMessage({hello: 'world'}, )

      // return
      const parser = code => acorn.parse(code, {
        sourceType: 'module',
        ecmaVersion: 6
      })

      const module = (name, code) => ({
        name,
        code
        // ast: parser(code)
      })
      const dashboard = module('dashboard', `export default {apa: 1234, foo() { return 'bar' }}`)
      // const util = module('util', `export default {util: true}`)
      const submitted = module('submitted', this.transformScript)
      /*
      const submitted = module('submitted', `
        // import chart from 'dashboard'
        export default {foo: 123, map(x) { return x }}
      `)
      */
      const ast = parser(`
        import main from 'submitted'
        main.map(${JSON.stringify(d.data)})
        `)

      const sandbox = sandboxr.create(ast, {
        ecmaVersion: 6,
        imports: [dashboard, submitted],
        parser
      })

      const result = sandbox.execute()

      window.console.log({
        result,
        native: result.toNative()
      })
    },
    loadAsset ({element, selector, props}) {
      return new Promise((resolve, reject) => {
        if (document.querySelector(selector)) {
          return resolve()
        }
        const el = document.createElement(element)
        Object.assign(el, props)
        el.addEventListener('load', resolve)
        el.addEventListener('error', reject)
        el.addEventListener('abort', reject)
        document.head.appendChild(el)
      })

    },
    loadScripts (srcs) {
      return srcs.reduce((p, src) => p.then(() => this.loadScript(src)), Promise.resolve())
    },
    loadStyles (hrefs) {
      return hrefs.reduce((p, href) => p.then(() => this.loadStyle(href)), Promise.resolve())
    },

    loadScript (src) {
      return this.loadAsset({
        element: 'script',
        selector: `script[src="${src}"]`,
        props: {
          type: 'text/javascript',
          async: true,
          src
        }
      })
    },
    loadStyle (href) {
      return this.loadAsset({
        element: 'link',
        selector: `link[href="${href}"]`,
        props: {
          rel: 'stylesheet',
          async: true,
          href
        }
      })
    }
  }
}
</script>
